$main-color: #2e3a44
$second-color: #d6dbd2
$accent-color: #ff8c42
$bg-color: #f0ebccff
$contrast-color: #76a1b2

$light: #fff
$dark: #101010

$text-font: 'Poppins', sans-serif
$title-font: 'Montserrat', sans-serif

body
    background-color: $bg-color

h2
    padding-bottom: 2rem

h3
    padding-top: 2rem

p, a
    font-family: $text-font

h1
    font-weight: 900
    font-style: italic


h1, h2, h3
    font-family: $title-font
    text-transform: uppercase

nav
    background-color: $main-color 
    color: $light

.navbar
    height: 10lvh
    &-brand
        
        a
            color: $light !important


#home
    color : $dark
    background: $second-color
    padding: 2rem
    img
        width: 100%
        padding: 2rem
        border-radius: 100%
        max-width: 50lvh
    a
        color: $dark

#skill
    background-color: $main-color
    color: $light
    .card
        background-color: $accent-color
        justify-content: space-around
        &-body
            display: flex
            flex-direction: column
            align-content: center
            justify-content: center

#home
    background-color: $accent-color

#certification
    .card
        margin: 1rem
        background-color: $accent-color
        min-width: 20lvw

.card
    margin: 1rem
    background-color: $accent-color
    &-container
        display: flex
        flex-direction: column
        align-content: center
        align-items: center
section
    padding: 2rem

#project
    background: $main-color
    color: $light
    .card
        background-color: $second-color

.btn-custom
    background-color: $contrast-color !important
    color: $light !important
    margin-top: 1rem
    margin-bottom: 1rem

.pagination

    color: $light !important
    --bs-pagination-active-bg: #76a1b2 !important
    --bs-pagination-active-border-color: #76a1b2 !important

.page-link
    --bs-pagination-color: #000!important
    font-weight: 900
    font-family: $title-font
    font-style: italic

#contact
    background-color: $accent-color
    label
        font-weight: bold
        
.breakpoint
    top: -10lvh
    position: relative

footer
    height: 10lvh
    background-color: $main-color
    display: flex
    justify-content: center
    align-items: center
    a
        text-decoration: none
        color: $light
        font-family: $title-font
        font-weight: 900
        font-style: italic
        