body {
  background-color: #f0ebcc;
}

h2 {
  padding-bottom: 2rem;
}

h3 {
  padding-top: 2rem;
}

p, a {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-weight: 900;
  font-style: italic;
}

h1, h2, h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

nav {
  background-color: #2e3a44;
  color: #fff;
}

.navbar {
  height: 10lvh;
}
.navbar-brand a {
  color: #fff !important;
}

#home {
  color: #101010;
  background: #d6dbd2;
  padding: 2rem;
}
#home img {
  width: 100%;
  padding: 2rem;
  border-radius: 100%;
  max-width: 50lvh;
}
#home a {
  color: #101010;
}

#skill {
  background-color: #2e3a44;
  color: #fff;
}
#skill .card {
  background-color: #ff8c42;
  justify-content: space-around;
}
#skill .card-body {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

#home {
  background-color: #ff8c42;
}

#certification .card {
  margin: 1rem;
  background-color: #ff8c42;
  min-width: 20lvw;
}

.card {
  margin: 1rem;
  background-color: #ff8c42;
}
.card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

section {
  padding: 2rem;
}

#project {
  background: #2e3a44;
  color: #fff;
}
#project .card {
  background-color: #d6dbd2;
}

.btn-custom {
  background-color: #76a1b2 !important;
  color: #fff !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pagination {
  color: #fff !important;
  --bs-pagination-active-bg: #76a1b2 !important;
  --bs-pagination-active-border-color: #76a1b2 !important;
}

.page-link {
  --bs-pagination-color: #000!important;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
}

#contact {
  background-color: #ff8c42;
}
#contact label {
  font-weight: bold;
}

.breakpoint {
  top: -10lvh;
  position: relative;
}

footer {
  height: 10lvh;
  background-color: #2e3a44;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer a {
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;
}

